const HabitatsFilters = {
  all: 'all',
  online: 'online',
  offline: 'offline',
  favorites: 'favorites',
} as const;

export type HabitatsFilter = keyof typeof HabitatsFilters;

export const toHabitatsFilter = (filter: string | null | undefined): HabitatsFilter | undefined => {
  const lowerCaseFilter = filter?.toLowerCase();
  switch (lowerCaseFilter) {
    case 'all':
    case 'online':
    case 'offline':
    case 'favorites':
      return lowerCaseFilter;
    default:
      return undefined;
  }
};

export default HabitatsFilters;
