import { faBell } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { differenceInMinutes } from 'date-fns';
import { useMemo, useState } from 'preact/hooks';

import HabitatLink from '@/components/HabitatLink';
import TalkReminderModal from '@/components/TalkReminderModal';
import { formatDateWithRelativeText } from '@/lib/date';
import { Event } from '@/queries/events/types';

type UpcomingEventItemProps = Pick<
  Event,
  | '_id'
  | 'animal'
  | 'businessHourId'
  | 'habitatId'
  | 'habitatImage'
  | 'habitatSlug'
  | 'eventType'
  | 'zooSlug'
  | 'title'
  | 'description'
  | 'startTime'
  | 'stopTime'
>;

const UpcomingEventItem = ({
  _id,
  animal,
  businessHourId,
  habitatId,
  habitatImage,
  habitatSlug,
  zooSlug,
  eventType,
  title,
  description,
  startTime,
  stopTime,
}: UpcomingEventItemProps) => {
  const [showReminderModal, setShowReminderModal] = useState(false);
  const { isOver, isHappening, durationInMinutes, label } = useMemo(() => {
    const now = Date.now();
    const start = Date.parse(startTime);
    const stop = Date.parse(stopTime);
    const isHappening = now >= start && now < stop;
    return {
      isOver: now >= stop,
      isHappening,
      durationInMinutes: differenceInMinutes(stop, start),
      label: isHappening ? 'Join now' : formatDateWithRelativeText(start),
    };
  }, [startTime, stopTime]);

  if (isOver) {
    return null;
  }

  return (
    <div className="grid grid-cols-[44px_1fr] gap-2 py-3">
      <div>
        <img src={habitatImage} alt={title} className="preflight preflight-img aspect-square rounded-md" />
      </div>
      <div>
        <div className="flex items-end">
          <h4 className="preflight preflight-h4 inline-block text-sm font-semibold leading-none">{title}</h4>
          <p className="preflight preflight-p ml-2 inline-block text-[10px] leading-none text-grey-6">
            {durationInMinutes} min {eventType === 'viv' ? 'talk' : 'feeding'}
          </p>
        </div>
        <p className="preflight preflight-p mb-3 mt-2 line-clamp-2 text-[10px] leading-tight text-grey-6">{description}</p>
        {isHappening ? (
          <HabitatLink
            habitatId={habitatId}
            slug={habitatSlug}
            zooSlug={zooSlug}
            className="preflight preflight-a block w-full rounded-full bg-primary-green px-3.5 py-1.5 text-center text-xs font-bold text-white hover:bg-secondary-green"
          >
            {label}
          </HabitatLink>
        ) : (
          <div className="flex items-center justify-between gap-2">
            <p className="preflight preflight-p min-w-0 grow text-xs font-bold text-primary-green">{label}</p>
            <div className="flex items-center gap-2">
              <HabitatLink
                habitatId={habitatId}
                slug={habitatSlug}
                zooSlug={zooSlug}
                className="preflight preflight-a rounded-full border-2 border-primary-green px-4 py-1 text-xs font-bold text-primary-green hover:bg-beige-light"
              >
                Visit
              </HabitatLink>
              <button
                type="button"
                className="preflight preflight-button size-7 rounded-full bg-primary-green text-base leading-none text-white hover:bg-secondary-green"
                onClick={() => setShowReminderModal(true)}
              >
                <FontAwesomeIcon icon={faBell} color="var(--white)" />
                <span className="sr-only">Remind me</span>
              </button>
            </div>
          </div>
        )}
      </div>
      {showReminderModal && (
        <TalkReminderModal
          id={_id}
          animal={animal}
          businessHourId={businessHourId}
          startTime={startTime}
          stopTime={stopTime}
          eventTitle={title}
          eventDescription={description}
          url={`${window.location.origin}/h/${zooSlug}/${habitatSlug}`}
          onClose={() => setShowReminderModal(false)}
        />
      )}
    </div>
  );
};

export default UpcomingEventItem;
