import ActionModal from 'Components/ActionModal';
import { NewOutlineButton, PrimaryButton } from 'Components/Buttons';
import ErrorModal from 'Components/modals/Error';
import { setShowPushNotificationModal } from 'Components/NotificationPermissionModal/actions';
import { useEffect, useState } from 'preact/hooks';
import { connect } from 'react-redux';

import { trackMP } from '@/shared/mp';

import {
  isInPushPermissionCohort,
  minuteMs,
  notificationPermissionStatus,
  requestNotificationPermission,
  weekMs,
} from '../../helpers';

const NotificationPermissionModal = ({ isOpen, role, setShowPushNotificationModalAction, cohorts }) => {
  const [showError, setShowError] = useState(false);
  const isInCohort = isInPushPermissionCohort(cohorts);
  useEffect(() => {
    let lastTimeShowedStr;
    let lastTimeShowed = null;
    try {
      lastTimeShowedStr = localStorage.getItem('lastTimeShowedNotificationModal');
      if (lastTimeShowedStr) {
        lastTimeShowed = parseInt(lastTimeShowedStr, 10);
      }
    } catch (err) {
      console.error('localstorage is not accessible');
      lastTimeShowed = null;
    }

    const currentTime = Date.now();
    const differenceTime = lastTimeShowed ? currentTime - lastTimeShowed : null;
    const isMonthPassed = differenceTime ? differenceTime > weekMs * 4 : true;

    if (isInCohort && isMonthPassed) {
      const timeout = setTimeout(async () => {
        try {
          localStorage.setItem('lastTimeShowedNotificationModal', currentTime.toString());
        } catch (err) {
          console.error('localstorage is not accessible');
        }
        const permissionStatus = await notificationPermissionStatus();
        if (permissionStatus !== 'granted') {
          setShowPushNotificationModalAction(true);
          trackMP('notification-permission-modal-showed');
        }
      }, 1 * minuteMs);

      return () => clearTimeout(timeout);
    }
    return null;
  }, [setShowPushNotificationModalAction, isInCohort]);

  if (!isOpen || role === 'host') {
    return null;
  }

  const onClose = () => setShowPushNotificationModalAction(false);
  const onAllow = async () => {
    const permissionDenied = (await notificationPermissionStatus()) === 'denied';
    if (permissionDenied) {
      setShowError(true);
    } else {
      requestNotificationPermission();
      onClose();
    }
  };

  return (
    <div>
      {showError && (
        <ErrorModal text="It seems you have denied permission to send you push notification already" onClose={onClose} />
      )}
      {!showError && (
        <ActionModal
          onClose={onClose}
          title="Keep up with the animals"
          subTitle="Receive alerts on significant animal updates, exclusive events, newly introduced habitats, and much more!"
          img={<img src="https://zoolife.tv/assets/notification_permission_modal.webp" alt="Notification" />}
          primaryButton={<PrimaryButton size="large" label="Allow" onClick={onAllow} className="w-full" />}
          secondaryButton={<NewOutlineButton size="large" label="Later" onClick={onClose} className="w-full" />}
        />
      )}
    </div>
  );
};

export default connect(
  ({
    user: { isOnboarded, role, cohorts },
    modals: {
      pushNotification: { isOpen },
    },
  }) => ({
    isOpen,
    isOnboarded,
    role,
    cohorts,
  }),
  {
    setShowPushNotificationModalAction: setShowPushNotificationModal,
  },
)(NotificationPermissionModal);
