import { createSelector } from '@reduxjs/toolkit';
import { openTermsModal } from 'Components/TermsAndConditions/redux/actions';
import { Box, Heading, Text } from 'grommet';
import { memo } from 'preact/compat';
import { useCallback, useMemo, useRef } from 'preact/hooks';
import { useDispatch } from 'react-redux';
import { trackMP } from 'Shared/mp';

import useOnMount from '@/hooks/useOnMount';
import { HabitatListItem } from '@/queries/habitats/types';
import { AppState, useSelector } from '@/redux/helper';
import { createSortedHabitatsSelector } from '@/redux/selectors/habitats';
import { selectIsFreeUser } from '@/redux/selectors/users';
import {
  getFreeHabitatIds,
  getHabitatDescription,
  getPrimaryButtonTitle,
  isHabitatLocked,
  onClickCardHandler,
} from '@/routes/map/helpers';

import HabitatMobileCard from '../HabitatMobileCard';
import HabitatsFilters from '../MobileMapAccordion/habitatsFilters';
import habitatListIsEmptySVG from './habitatListIsEmptySVG.svg';
import heartCircleGraphicSVG from './heartCircleGraphicSVG.svg';

interface MobileMapProps {
  onShowTrailer?: (trailer: HabitatListItem['trailer']) => void;
  filterHabitatsBy?: string;
}

const selectFreeHabitatIds = createSelector(
  (state: AppState) => state.allHabitats,
  (state: AppState) => state.user.logged,
  (state: AppState) => state.user.subscription.active,
  (state: AppState) => state.user.subscription.freeHabitats,
  (state: AppState) => state.config.isMultiFreeHabitat,
  (allHabitats, loggedIn, active, freeHabitats, isMultiFreeHabitat) => {
    const ids = getFreeHabitatIds({
      habitats: allHabitats,
      subscription: { active, freeHabitats },
      loggedIn,
      isMultiFreeHabitat,
    });
    return ids ? new Set(ids) : undefined;
  },
);

const selectFavoriteHabitats = createSelector(
  (state: AppState) => state.user.favoriteHabitats,
  (habitats) => (habitats ? new Set(habitats) : undefined),
);

const MobileMap = ({ onShowTrailer, filterHabitatsBy }: MobileMapProps) => {
  const dispatch = useDispatch();
  const allHabitats = useSelector((state) => state.allHabitats);
  const subscription = useSelector((state) => state.user.subscription);
  const loggedIn = useSelector((state) => state.user.logged);
  const termsAccepted = useSelector((state) => state.user.termsAccepted) ?? false;
  const favoriteHabitats = useSelector(selectFavoriteHabitats);
  const selectSortedHabitats = useMemo(() => createSortedHabitatsSelector(), []);
  const sortedHabitats = useSelector(selectSortedHabitats);

  const divRef = useRef<HTMLDivElement>(null);
  const freeHabitatIds = useSelector(selectFreeHabitatIds);
  const isFreeUser = useSelector(selectIsFreeUser);

  const isFree = useCallback(
    (habitatId: string) => isFreeUser && freeHabitatIds?.has(habitatId),
    [freeHabitatIds, isFreeUser],
  );

  const isFavorite = useCallback((habitatId: string) => favoriteHabitats?.has(habitatId), [favoriteHabitats]);

  const habitatDescription = (habitat: HabitatListItem) => getHabitatDescription({ habitat, loggedIn, freeHabitatIds });

  const isLocked = useCallback((habitat: HabitatListItem) => isHabitatLocked({ habitatId: habitat._id }), []);

  const primaryButtonTitle = (habitat: HabitatListItem) =>
    getPrimaryButtonTitle({
      habitat,
      loggedIn,
      freeHabitatIds,
    });

  const onClickHandler = (habitat: HabitatListItem) =>
    onClickCardHandler({
      habitat,
      loggedIn,
      termsAccepted,
      openTermsModalAction: () => dispatch(openTermsModal()),
      freeHabitatIds,
    });

  const filteredHabitats = useMemo(
    () =>
      filterHabitatsBy === HabitatsFilters.online
        ? sortedHabitats?.filter((item) => item.isStreamOn)
        : filterHabitatsBy === HabitatsFilters.offline
        ? sortedHabitats?.filter((item) => !item.isStreamOn)
        : filterHabitatsBy === HabitatsFilters.favorites
        ? sortedHabitats?.filter((item) => isFavorite(item._id))
        : sortedHabitats,
    [filterHabitatsBy, isFavorite, sortedHabitats],
  );

  const sections = useMemo(
    () =>
      !filteredHabitats
        ? undefined
        : (isFreeUser
            ? [
                {
                  data: filteredHabitats.filter((item) => !isLocked(item)),
                  title: 'Free Habitats',
                  description: 'Visit for an interactive experience.',
                },
                {
                  data: filteredHabitats.filter((item) => isLocked(item)),
                  title: 'Unlock to Visit',
                },
              ]
            : filterHabitatsBy === HabitatsFilters.favorites
            ? [
                {
                  data: filteredHabitats,
                  title: 'All Habitats',
                },
              ]
            : [
                {
                  data: filteredHabitats.slice(0, 4),
                  title: 'Recommended',
                },
                {
                  data: filteredHabitats.slice(4),
                  title: 'Other Habitats',
                },
              ]
          ).filter((item) => item.data.length > 0),
    [filterHabitatsBy, filteredHabitats, isFreeUser, isLocked],
  );

  const isEmpty = filteredHabitats.length === 0;

  useOnMount(() => {
    if (!allHabitats?.length) {
      return;
    }
    const mapHabitatListForMP = (habitatList: HabitatListItem[]) =>
      habitatList.map(({ _id, animal }) => ({ habitatId: _id, habitatName: animal }));
    const divTags = divRef.current?.querySelectorAll('div[data-title]');
    const onlineHabitatsFromHTMLTags: string[] = [];
    const liveTalkHabitatsFromHTMLTags: string[] = [];
    const offlineHabitatsFromHTMLTags: string[] = [];
    divTags?.forEach((elm) => {
      const div = elm as HTMLDivElement;
      const habitatTitle = div.dataset.title ?? '';
      if (div.dataset.livetalk) {
        liveTalkHabitatsFromHTMLTags.push(habitatTitle);
      } else if (div.dataset.onlinehabitat) {
        onlineHabitatsFromHTMLTags.push(habitatTitle);
      } else if (div.dataset.offlinehabitat) {
        offlineHabitatsFromHTMLTags.push(habitatTitle);
      }
    });

    trackMP('map', {
      onlineHabitats: mapHabitatListForMP(allHabitats.filter((habitat) => habitat.isStreamOn && !habitat.liveTalk)),
      liveTalkHabitats: mapHabitatListForMP(allHabitats.filter((habitat) => habitat.liveTalk)),
      offlineHabitats: mapHabitatListForMP(allHabitats.filter((habitat) => !habitat.liveTalk && !habitat.isStreamOn)),
      onlineHabitatsFromHTMLTags,
      liveTalkHabitatsFromHTMLTags,
      offlineHabitatsFromHTMLTags,
    });

    if (!subscription?.active) {
      trackMP('online-free-habitats-status', {
        onlineFreeHabitats: mapHabitatListForMP(
          allHabitats?.filter((habitat) => habitat.isStreamOn && subscription?.freeHabitats?.includes(habitat._id)),
        ),
      });
    }
  });

  return (
    <div ref={divRef}>
      {sections?.map(({ data, title, description }) => (
        <div key={title} className="min-h-fit">
          <div className="py-4">
            <h3 className="preflight preflight-h3 text-base/8 font-bold text-[#090A0A]">{title}</h3>
            {description && <p className="preflight preflight-p text-sm/4 font-light text-grey-3">{description}</p>}
          </div>
          <div className="flex min-h-fit flex-col gap-1">
            {data.map((habitat) => (
              <HabitatMobileCard
                key={habitat._id}
                habitat={habitat}
                onShowTrailer={onShowTrailer}
                showFreemiumTag={isFree(habitat._id)}
                description={habitatDescription(habitat)}
                isLocked={isLocked(habitat)}
                primaryButtonTitle={primaryButtonTitle(habitat)}
                onClickPrimaryButton={() => onClickHandler(habitat)}
                online={habitat.isStreamOn}
                isOffline={!habitat.liveTalk && !habitat.isStreamOn}
                isLiveTalk={habitat.liveTalk && habitat.isStreamOn}
              />
            ))}
          </div>
        </div>
      ))}
      {isEmpty && (
        <Box height={{ min: 'fit-content' }} margin={{ bottom: '25px' }}>
          {filterHabitatsBy === HabitatsFilters.favorites ? (
            <img
              src={heartCircleGraphicSVG}
              alt="No habitat found"
              style={{
                width: '25%',
                margin: 'auto',
                display: 'block',
                paddingTop: '30px',
                paddingBottom: '10px',
              }}
            />
          ) : (
            <img
              src={habitatListIsEmptySVG}
              alt="No habitat found"
              style={{ paddingInline: '50px', paddingTop: '0px', paddingBottom: '0px' }}
            />
          )}
          <Heading style={{ color: 'rgb(83, 83, 83)' }} level="3" textAlign="center">
            {filterHabitatsBy === HabitatsFilters.favorites
              ? `You don't follow any habitats yet`
              : 'Looks like there are no habitats in this tab'}
          </Heading>
          <Text
            textAlign="center"
            style={{
              fontSize: '16px',
              color: 'rgb(83, 83, 83)',
              paddingBottom: '10px',
              lineHeight: '1.5',
            }}
          >
            {filterHabitatsBy === HabitatsFilters.favorites
              ? 'Follow by tapping the heart icon in any habitat to receive animal updates.'
              : 'Please change your tab.'}
          </Text>
        </Box>
      )}
    </div>
  );
};
export default memo(MobileMap);
