import { useCallback, useMemo } from 'preact/hooks';
import { useRouter } from 'preact-router';

type Query = Record<string, string | null | undefined>;

const mergeParams = (params: URLSearchParams, query: Query) => {
  Object.entries(query).forEach(([key, value]) => {
    if (value == null) {
      params.delete(key);
      return;
    }
    params.set(key, value);
  });
  return params;
};

export const getUrl = (path: string | null | undefined, query: Query = {}) => {
  const finalPath = path ?? window?.location.pathname ?? '';
  const params = new URLSearchParams(window?.location.search);
  mergeParams(params, query);
  return `${finalPath}?${params.toString()}`;
};

export const useQueryParams = () => {
  const [{ url }] = useRouter();
  // this is a hack to make sure the query params are always up to date when the url changes
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => new URLSearchParams(window?.location.search), [url]);
};

export const useSetQueryParams = () => {
  const [, route] = useRouter();
  const params = useQueryParams();
  return useCallback(
    (query: Query, replace = false) => {
      mergeParams(params, query);
      route(`${window.location.pathname}?${params.toString()}`, replace);
    },
    [params, route],
  );
};
