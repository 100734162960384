import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Heading, Text } from 'grommet';
import { ReactNode, useEffect, useState } from 'preact/compat';

import { useQueryParams, useSetQueryParams } from '@/hooks/route';
import { HabitatListItem } from '@/queries/habitats/types';

import { updateIframeHeight } from '../../IframeView/helpers';
import MobileMap from '../MobileMap';
import HabitatsFilters, { HabitatsFilter, toHabitatsFilter } from './habitatsFilters';
import style from './style.scss';

interface Props {
  onShowTrailer?: (trailer: HabitatListItem['trailer']) => void;
  children?: ReactNode;
  showCollapsed?: boolean;
}

const MobileMapAccordion = ({ onShowTrailer, children, showCollapsed }: Props) => {
  const [isExtended, setExtended] = useState(false);
  const params = useQueryParams();
  const setParams = useSetQueryParams();
  const habitatsFilter = toHabitatsFilter(params.get('tab')) ?? HabitatsFilters.online;
  const setHabitatsFilter = (tab: string) => {
    setParams({ tab }, true);
  };

  useEffect(() => {
    if (window.iframeContext?.ref) {
      updateIframeHeight(window.iframeContext.ref);
    }
  }, [isExtended]);

  if (showCollapsed) {
    return (
      <div className={style.body}>
        <div className={style.expandableTitle}>
          <div>
            <Heading level="2">Explore the animal kingdom.</Heading>
            <Text className={style.text} size="xlarge" color="var(--charcoal)">
              Start by selecting an animal
            </Text>
          </div>
          <div onClick={() => setExtended(!isExtended)} className={style.icon}>
            <FontAwesomeIcon icon={isExtended ? faChevronUp : faChevronDown} />
          </div>
        </div>
        {isExtended && (children || <MobileMap onShowTrailer={onShowTrailer} />)}
      </div>
    );
  }

  const renderButton = (label: string, filter: HabitatsFilter, tabIndex: number) => (
    <a
      data-native
      href={`?tab=${filter}`}
      role="radio"
      aria-checked={habitatsFilter === filter}
      tabIndex={tabIndex}
      className="preflight preflight-a rounded-full border border-white bg-secondary-green px-4 py-1 text-xs font-bold text-white aria-checked:bg-white aria-checked:text-grey-1"
      onClick={(e) => {
        e.preventDefault();
        setHabitatsFilter(filter);
      }}
    >
      {label}
    </a>
  );

  return (
    <>
      <div role="radiogroup" className="sticky top-0 z-10 flex flex-wrap gap-2 bg-secondary-green py-4">
        {renderButton('Online', HabitatsFilters.online, 0)}
        {renderButton('Offline', HabitatsFilters.offline, -1)}
        {renderButton('All', HabitatsFilters.all, -1)}
        {renderButton('Following', HabitatsFilters.favorites, -1)}
      </div>
      <div className={style.body}>
        {children || <MobileMap onShowTrailer={onShowTrailer} filterHabitatsBy={habitatsFilter} />}
      </div>
    </>
  );
};

export default MobileMapAccordion;
