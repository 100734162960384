import { COMMENT_TRUNCATE_LEN_SHORT, truncateString } from '@/helpers';
import { MediaCreatedMessage } from '@/queries/community-feed/types';

import Card, { CardProps } from './Card';
import { CardHabitatLink } from './CardLink';
import { getMediaQueryParams } from './utils';

const MediaCreatedCard = ({ messageWithMeta }: CardProps<MediaCreatedMessage>) => {
  const { message } = messageWithMeta;
  const mediaType = 'media' in message ? message.media.type : message.mediaType;
  return (
    <Card messageWithMeta={messageWithMeta}>
      {'media' in message && (
        <p className="preflight inline-flex gap-2">
          <CardHabitatLink message={message} query={getMediaQueryParams(message)} className="flex-none">
            <img
              className="preflight preflight-img h-14 w-auto overflow-hidden rounded"
              src={message.media.previewURL}
              alt="text"
            />
          </CardHabitatLink>
          <p className="preflight preflight-p line-clamp-3 shrink overflow-hidden break-words text-left text-xs">
            &quot;
            <CardHabitatLink message={message} query={getMediaQueryParams(message)}>
              {truncateString(message.media.title, COMMENT_TRUNCATE_LEN_SHORT)}
            </CardHabitatLink>
            &quot; a {mediaType === 'video' ? 'clip' : 'photo'} was taken of the{' '}
            <CardHabitatLink message={message}>
              {truncateString(messageWithMeta.message.habitat.title, COMMENT_TRUNCATE_LEN_SHORT)}
            </CardHabitatLink>
          </p>
        </p>
      )}
    </Card>
  );
};

export default MediaCreatedCard;
