import { useState } from 'preact/hooks';

import { useIsMobileLayout } from '@/components/LayoutDetector/hooks';
import MobileMapAccordion from '@/components/Map/MobileMapAccordion';
import ShareModal from '@/components/ShareModal/Standalone';
import { HabitatListItem } from '@/queries/habitats/types';

import style from './style.scss';

const MobileMap = ({ path }: { path?: string }) => {
  const isMobileLayout = useIsMobileLayout();
  const [trailer, setTrailer] = useState<HabitatListItem['trailer'] | undefined>();

  return (
    <div className={style.mobileMap}>
      <div data-path={path} className={style.mapWrapper}>
        <MobileMapAccordion onShowTrailer={setTrailer} />
      </div>
      {trailer && typeof window !== 'undefined' && (
        <ShareModal
          open
          data={trailer}
          onClose={() => setTrailer(undefined)}
          mediaId={trailer?._id ?? ''}
          isDownloadAllowed={false}
          shouldLoadPubnub
          isTrailer
          nextId={undefined}
          prevId={undefined}
          setShareModalMediaId={undefined}
          slug={undefined}
          isGuest={undefined}
          hashtags={undefined}
          isDrawer={isMobileLayout}
        />
      )}
    </div>
  );
};

export default MobileMap;
