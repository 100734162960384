import { queryOptions, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'preact/hooks';
import Pubnub from 'pubnub';
import { usePubNub } from 'pubnub-react';

import { FeedMessageWithMeta } from './types';

const CHANNEL = 'communityUpdates';

const queryFn = async (
  pubnub: Pubnub | undefined,
  channelId: string,
  params: Omit<Pubnub.FetchMessagesParameters, 'channels'>,
) => {
  const resp = await pubnub?.fetchMessages({
    channels: [channelId],
    ...params,
  });
  return (resp?.channels?.[CHANNEL].reverse() ?? []) as FeedMessageWithMeta[];
};

const getCommunityFeedQueryOptions = (pubnub: Pubnub | undefined, count: number) =>
  queryOptions({
    queryKey: [pubnub, 'messages', 'list', CHANNEL, { count }],
    queryFn: () => queryFn(pubnub, CHANNEL, { count }),
    enabled: !!pubnub,
    // data will be updated through pubnub listener
    staleTime: Infinity,
  });

// eslint-disable-next-line import/prefer-default-export
export const useCommunityFeedQuery = (initialCount: number) => {
  const pubnub = usePubNub();
  const queryClient = useQueryClient();
  const query = useQuery(getCommunityFeedQueryOptions(pubnub, initialCount));

  // subscribe to new messages and update the query data directly
  useEffect(() => {
    const handler: Pubnub.ListenerParameters['message'] = (event) => {
      if (event.channel !== CHANNEL) return;
      queryClient.setQueryData(getCommunityFeedQueryOptions(pubnub, initialCount).queryKey, (oldData) => {
        return oldData ? [event, ...oldData] : [event];
      });
    };
    pubnub.addListener({ message: handler });
    pubnub.subscribe({ channels: [CHANNEL] });
    return () => {
      pubnub.unsubscribe({ channels: [CHANNEL] });
      pubnub.removeListener({ message: handler });
    };
  }, [initialCount, pubnub, queryClient]);

  return query;
};
