import { StepType, TourProvider } from '@reactour/tour';
import { noop } from 'lodash-es';
import { ComponentProps } from 'preact/compat';

import AppOnboarding from '@/components/AppOnboarding';
import FreeHabitatSelectorModal from '@/components/FreeHabitatsSelector/FreeHabitatSelectorModal';
import Header from '@/components/Header';
import { LayoutDetector } from '@/components/LayoutDetector';
import PageWrapper from '@/components/Main/PageWrapper';
import HomeLayout from '@/layouts/Home';

import HomeContent from './HomeContent';
import SpotlightFreeHabitats from './SpotlightFreeHabitats';

const Home = () => {
  return (
    <HomeLayout>
      <HomeContent />
      <AppOnboarding />
      <FreeHabitatSelectorModal />
      <SpotlightFreeHabitats />
    </HomeLayout>
  );
};

const PLACEHOLDER_STEPS: StepType[] = [];

const HomeWithLayout = () => {
  const tourProps: Omit<ComponentProps<typeof TourProvider>, 'children'> = {
    steps: PLACEHOLDER_STEPS,
    showCloseButton: false,
    disableDotsNavigation: true,
    disableKeyboardNavigation: true,
    disableInteraction: true,
    disableFocusLock: true,
    showBadge: false,
    ContentComponent: SpotlightFreeHabitats.TourContent,
    onClickMask: noop,
  };
  return (
    <LayoutDetector
      title="Habitats"
      breakPoint={1023} // keep it as stable as possible in mobile device to prevent layout change when orientation changes
      desktop={
        // There is a bug in reactour that the padding cannot be set to 0 in an array, so we use 0.01 as a workaround
        // eslint-disable-next-line react/jsx-props-no-spreading
        <TourProvider padding={{ mask: [0.01, 8] }} {...tourProps}>
          <Header />
          <PageWrapper>
            <Home />
          </PageWrapper>
        </TourProvider>
      }
      mobile={
        // eslint-disable-next-line react/jsx-props-no-spreading
        <TourProvider padding={{ mask: 0 }} {...tourProps}>
          <Home />
        </TourProvider>
      }
    />
  );
};

export default HomeWithLayout;
