import { faSnooze } from '@fortawesome/pro-regular-svg-icons';
import { faCheck, faCommentExclamation, faStars, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation } from '@tanstack/react-query';
import { useMemo, useRef, useState } from 'preact/hooks';
import { useDispatch } from 'react-redux';

import { HabitatListItem } from '@/queries/habitats/types';
import { putUserFreeHabitatsMutationFn } from '@/queries/users';
import { useSelector } from '@/redux/helper';
import { selectFreeHabitatsIndexMap } from '@/redux/selectors/users';
import { setUserData } from '@/redux/slices/user/actions';
import { buildURL, get } from '@/shared/fetch';
import { trackMP } from '@/shared/mp';

import { flagAppOnboardingModalStep } from '../AppOnboarding/redux/actions';
import Loader from '../Loader';
import backgroundSvg from './assets/background.svg';

interface FreeHabitatsSelectorItemProps {
  item: HabitatListItem & { _isPremium: boolean; _sortingOrder: number };
  selectedIndex: number;
  onClick?: () => void;
}

const FreeHabitatsSelectorItem = ({ item, selectedIndex, onClick }: FreeHabitatsSelectorItemProps) => {
  return (
    <button
      role="checkbox"
      type="button"
      aria-checked={selectedIndex >= 0}
      style={{ backgroundImage: `url("${item.backgroundImage || item.profileImage}")` }}
      className="preflight preflight-button group relative flex grow flex-col justify-end overflow-hidden rounded-lg bg-cover bg-center shadow-lg !outline-offset-[3px] transition-transform aria-checked:!outline aria-checked:!outline-[3px] aria-checked:!outline-primary-green desktop:hover:scale-105 desktop:hover:transform-gpu"
      onClick={onClick}
    >
      {!item.backgroundImage && <div className="absolute inset-0 rounded-lg bg-slate-300/20 backdrop-blur" />}
      <div className="absolute -inset-x-0.5 -bottom-0.5 rounded-t-full bg-white p-4">
        <div className="mb-2 bg-contain bg-center bg-no-repeat p-0.5" style={{ backgroundImage: `url("${backgroundSvg}")` }}>
          <div className="relative mx-auto size-16">
            <img src={item.profileImage} alt={item.title} className="preflight preflight-img size-full rounded-full" />
            <div className="absolute inset-0 hidden items-center justify-center rounded-full bg-[#265832]/25 text-white backdrop-blur-[2px] group-aria-checked:flex">
              <FontAwesomeIcon icon={faCheck} size="2x" />
            </div>
            <span data-online={item.isStreamOn} className="group absolute bottom-0.5 right-1 flex size-3">
              <span className="absolute inline-flex size-full animate-ping rounded-full bg-online-green opacity-75 group-data-[online=false]:hidden" />
              <span className="relative inline-flex size-full rounded-full bg-grey-3 group-data-[online=true]:bg-online-green" />
            </span>
          </div>
        </div>
        <h4 className="preflight preflight-h4 text-xs font-bold">{item.title}</h4>
        <p className="preflight preflight-p mt-1 text-[10px] font-light leading-tight text-grey-6">{item.zoo.name}</p>
      </div>
      <div
        data-type={item._isPremium ? 'premium' : item.isStreamOn ? 'online' : 'offline'}
        className="absolute right-2 top-2 flex items-center gap-1 rounded bg-secondary-green px-2 py-1 text-xs font-bold text-white data-[type=offline]:bg-grey-5 data-[type=premium]:bg-primary-yellow data-[type=offline]:text-grey-3 data-[type=premium]:text-grey-1"
      >
        {item._isPremium ? (
          <>
            <FontAwesomeIcon icon={faStars} />
            Premium
          </>
        ) : item.isStreamOn ? (
          <>
            <i className="size-1.5 rounded-full bg-current" />
            Live
          </>
        ) : (
          <>
            <FontAwesomeIcon icon={faSnooze} className="text-[10px]" />
            Asleep
          </>
        )}
      </div>
    </button>
  );
};

const FreeHabitatsSelector = () => {
  const dispatch = useDispatch();
  const allHabitats = useSelector((state) => state.allHabitats);
  const currentFreeHabitats = useSelector(selectFreeHabitatsIndexMap);
  const maximumNumberOfFreeHabitats = useSelector((state) => state.user.maximumNumberOfFreeHabitats);

  const [showPremiumBanner, setShowPremiumBanner] = useState(false);
  const [freeHabitatIds, setFreeHabitatIds] = useState<string[]>([]);
  const orderRef = useRef<string[]>([]);

  const { mutate, isPending } = useMutation({
    mutationFn: putUserFreeHabitatsMutationFn,
    onSuccess: () => {
      dispatch(flagAppOnboardingModalStep('habitatsSelector'));
      get(buildURL('/users/user')).then((user) => user && dispatch(setUserData(user)));
    },
  });

  const habitats = useMemo(() => {
    if (!allHabitats || allHabitats.length === 0) {
      return undefined;
    }
    const sorted = allHabitats
      .map((item) => {
        const isPremium = !item.isFreemium && (currentFreeHabitats?.get(item._id) ?? -1) < 0;
        const previousIndex = orderRef.current.indexOf(item._id);
        return {
          ...item,
          _isPremium: isPremium,
          _sortingOrder:
            previousIndex >= 0
              ? previousIndex
              : !item.isFreemium && !isPremium
              ? currentFreeHabitats?.get(item._id) ?? Number.MAX_SAFE_INTEGER
              : Number.MAX_SAFE_INTEGER,
        };
      })
      .sort(
        (a, b) =>
          a._sortingOrder - b._sortingOrder ||
          Number(a._isPremium) - Number(b._isPremium) ||
          Number(b.isStreamOn) - Number(a.isStreamOn) ||
          a.title.localeCompare(b.title),
      );

    orderRef.current = sorted.map((item) => item._id);
    return sorted;
  }, [allHabitats, currentFreeHabitats]);

  const makeOnClick = (item: NonNullable<typeof habitats>[number]) => () => {
    if (!item.isFreemium && !currentFreeHabitats?.has(item._id)) {
      setShowPremiumBanner(true);
      return;
    }
    setShowPremiumBanner(false);
    setFreeHabitatIds((prev) => {
      if (prev.includes(item._id)) {
        return prev.filter((id) => id !== item._id);
      }
      if (prev.length >= maximumNumberOfFreeHabitats) {
        return prev;
      }
      return [...prev, item._id];
    });
  };

  const onSubmit = () => {
    mutate(freeHabitatIds);
    const [offline, online] = (allHabitats ?? []).reduce(
      (acc, curr) => {
        if (!freeHabitatIds.includes(curr._id)) {
          return acc;
        }
        acc[Number(curr.isStreamOn)].push(curr._id);
        return acc;
      },
      [[], []] as [string[], string[]],
    );
    trackMP('freehabitats-selection-status', {
      totalOnlineFreeHabitats: online.length,
      totalOfflineFreeHabitats: offline.length,
    });
  };

  return (
    <div className="flex h-full flex-col overflow-y-auto desktop:w-[784px] desktop:rounded-lg">
      <div className="relative flex min-h-0 grow bg-beige-light">
        <div className="min-h-0 grow overflow-y-auto">
          <ul className="preflight preflight-ul grid grid-cols-2 gap-4 p-4 desktop:grid-cols-4 desktop:p-7">
            {habitats?.map((habitat) => (
              <li key={habitat._id} className="flex aspect-[3/4]">
                <FreeHabitatsSelectorItem
                  item={habitat}
                  selectedIndex={freeHabitatIds.indexOf(habitat._id)}
                  onClick={makeOnClick(habitat)}
                />
              </li>
            ))}
          </ul>
        </div>
        {showPremiumBanner && (
          <div className="preflight absolute inset-x-4 bottom-4 flex overflow-hidden rounded-lg bg-white shadow-lg shadow-black/20 desktop:inset-x-7">
            <div className="flex shrink-0 items-center bg-[#FFF3CC] p-4 text-xl text-primary-yellow">
              <FontAwesomeIcon icon={faCommentExclamation} />
            </div>
            <div className="min-w-0 grow p-4">
              <h4 className="preflight preflight-h4 text-sm font-bold">Oops, that’s a premium habitat!</h4>
              <p className="preflight preflight-p mt-1 text-xs font-light text-grey-6">
                Choose a free habitat to proceed. You can upgrade your membership later to get full access to all premium
                habitats.
              </p>
            </div>
            <div className="shrink-0 py-4 pr-4">
              <button type="button" className="preflight preflight-button" onClick={() => setShowPremiumBanner(false)}>
                <FontAwesomeIcon icon={faXmark} />
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="preflight flex flex-col p-6 desktop:flex-row desktop:items-center desktop:border-t desktop:px-9">
        <div className="grow mobile:mb-6">
          <h4 className="preflight preflight-h4 text-base font-bold">
            Choose {maximumNumberOfFreeHabitats} habitats to visit anytime for free{' '}
            <span
              className={
                freeHabitatIds.length === maximumNumberOfFreeHabitats
                  ? 'text-primary-green'
                  : freeHabitatIds.length < maximumNumberOfFreeHabitats
                  ? 'text-grey-3'
                  : 'text-system-red'
              }
            >
              ({freeHabitatIds.length}/{maximumNumberOfFreeHabitats})
            </span>
          </h4>
          <div className="mt-1 flex flex-wrap gap-2 text-xs">
            {/* TODO: add habitat name to button for easier deselection */}
            {/* eslint-disable-next-line no-constant-condition */}
            {freeHabitatIds.length && false ? (
              freeHabitatIds.map((id) => {
                const habitat = habitats?.find((habitat) => habitat._id === id)!;
                return (
                  <button
                    type="button"
                    key={habitat._id}
                    className="preflight preflight-button inline-flex items-center gap-1 rounded bg-beige-light px-2 py-1 text-primary-green desktop:hover:bg-secondary-green desktop:hover:text-white"
                    onClick={makeOnClick(habitat)}
                  >
                    {habitat.title}
                    <FontAwesomeIcon icon={faXmark} />
                  </button>
                );
              })
            ) : (
              <p className="preflight preflight-p font-light text-grey-6">
                You can control the camera, take photos and clips, and attend live events on these 3 habitats
              </p>
            )}
          </div>
        </div>
        <button
          type="button"
          disabled={freeHabitatIds.length < maximumNumberOfFreeHabitats || isPending}
          className="preflight preflight-button w-full self-center rounded-full bg-primary-green p-4 text-base/none font-bold text-white hover:bg-secondary-green disabled:bg-grey-4 desktop:w-auto desktop:min-w-[200px]"
          onClick={onSubmit}
        >
          {isPending ? <Loader width="16px" height="16px" color="var(--white)" /> : 'Start Exploring'}
        </button>
      </div>
    </div>
  );
};

export default FreeHabitatsSelector;
